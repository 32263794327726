<template>
    <div class="spinner-container" v-if="isViewerLoading">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <PropertiesGrid v-else :dataObject="props.dataObject" hideOkCancel>
        <template v-for="(group, index) in groupedAttributes" :key="group.name">
            <PropertiesGroup :caption="group.label?.value ?? group.label"  :collapsed="isMobile ? undefined : (props.collapsed && group.name !== 'Required')">
                <template v-for="attribute in group.attributes" :key="attribute.Field">
                    <span v-if="(props.dataObject.current.hasOwnProperty(attribute.Field) && !hiddenFields.includes(attribute.Field)) || attribute.InputType == 'Custom' || group.name === 'Required'">
                        <PropertiesItem v-if="attribute.InputType" :caption="attribute.Label?.value ?? attribute.Label" :fieldName="attribute.Field" :required="attribute.Required" :title="attribute.Title">
                            <OMediaQueryProvider>
                                <OMatchMedia v-slot="{ isMobileOrTablet }">  
                                    <ODataLookup v-if="attribute.InputType == 'ODataLookup'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" :disabled="(disabledDO || attribute.Disabled) && attribute.Field != 'ObsoleteReason'" v-model="props.dataObject.current[attribute.Field]" class="form-control" 
                                        :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear"
                                        :class="{'border border-danger': attribute.Title == 'value not available',  'border border-danger' : attribute.Required }" :readonly="attribute.TextInput != undefined ? !attribute.TextInput : undefined" @beforeopen="attribute.beforeopen && attribute.beforeopen()">
                                        <template v-if="attribute.TextInput" #target="{ target }">
                                            <input class="form-control" :readonly="!attribute.TextInput ?? true" :ref="target" v-model="props.dataObject.current[attribute.Field]">
                                        </template>
                                        <OColumn v-if="isMobileOrTablet" :name="attribute.Columns[0].field"></OColumn> 
                                    </ODataLookup>
                                    <ODataLookup v-else-if="attribute.InputType == 'ODataLookupCheckBoxes'" :bind="sel => setCheckBoxValues(sel, attribute.Field, attribute.Columns[0].field)" 
                                                :itemLoaded="lkpItem => checkBoxesIsSelected(lkpItem, props.dataObject.current[attribute.Field], attribute.Columns[0].field)" :disabled="disabledDO || attribute.Disabled" 
                                                :value="getCheckboxesJsonValue(props.dataObject.current[attribute.Field])" class="form-control" :data-object="attribute.DataObject" :columns="attribute.Columns" 
                                                :whereClause="attribute.WhereClause" multiselect
                                                :class="{'border border-danger': attribute.Title == 'value not available',  'border border-danger' : attribute.Required }">
                                        <OColumn v-if="isMobileOrTablet" :name="attribute.Columns[0].field"></OColumn> 
                                    </ODataLookup>
                                    <OPersonsLookup v-else-if="attribute.InputType == 'OPersonsLookup'" :disabled="(disabledDO || attribute.Disabled)" :bind="sel => {attribute.Bind(sel); saveDataObject()}" v-model="props.dataObject.current[attribute.Field]" class="form-control" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{ 'border border-danger': attribute.Title == 'value not available',  'border border-danger' : attribute.Required }"/>
                                    <ONumberEditor v-else-if="attribute.InputType == 'input%'" type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control" :disabled="disabledDO || attribute.Disabled" format="%" :class="{'border border-danger' : attribute.Required}"/>
                                    <input v-else-if="attribute.InputType == 'input'" type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control" :disabled="disabledDO || attribute.Disabled" :class="{ 'border border-danger': attribute.Title == 'value not available',  'border border-danger' : attribute.Required }">
                                    <input v-else-if="attribute.InputType == 'Custom'" type="text" :value="attribute.Value" class="form-control" disabled :class="{'border border-danger' : attribute.Required }">
                                    <select v-else-if="attribute.InputType == 'select'" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control lookup-icon" :disabled="disabledDO || attribute.Disabled" :class="{ 'border border-danger': attribute.Title == 'value not available',  'border border-danger' : attribute.Required }">
                                        <option v-for="op,i in attribute.InputOptions" :value="op.value">&nbsp;{{ $t(op.name) }}</option>
                                    </select>
                                    <OTextArea v-else-if="attribute.InputType == 'OTextArea'" @blur="saveDataObject" :renderHTML="attribute.RenderHTML" :disabled="disabledDO || attribute.Disabled" rows="attribute.Rows" class="form-control" v-model="props.dataObject.current[attribute.Field]" :class="{ 'border border-danger': attribute.Title == 'value not available',  'border border-danger' : attribute.Required }"> </OTextArea>
                                    <o-date-picker v-else-if="attribute.InputType == 'o-date-picker' && (!attribute.IfField || props.dataObject.current[attribute.IfField])" class="form-control" :callback="saveDataObject" v-model="props.dataObject.current[attribute.Field]" :disabled="(disabledDO || attribute.Disabled) && attribute.Field != 'Obsolete'" format="Short Date" date :class="{ 'border border-danger': attribute.Title == 'value not available',  'border border-danger' : attribute.Required }"></o-date-picker>
                                    <div class="d-flex" v-else-if="attribute.InputType == 'input2x'">
                                        <ONumberEditor  type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="w-25 border-0" :disabled="disabledDO || attribute.Disabled" format="%" :class="{'border border-danger' : attribute.Required }"/>
                                        <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field2]" class="w-75 border-0 ms-1" :disabled="disabledDO || attribute.Disabled" :class="{'border border-danger' : attribute.Required }">
                                    </div>
                                </OMatchMedia>  
                            </OMediaQueryProvider> 
                        </PropertiesItem>
                        <PropertiesItem v-else-if="attribute.Field" :caption="attribute.Label?.value ?? attribute.Label" :fieldName="attribute.Field" :required="attribute.Required" :title="attribute.Title"></PropertiesItem>
                    </span>
                </template>
                <template v-if="group.name==='Required'">
                    <OPropertiesForm :row="props.dataObject.current" viewName="aviw_arena_DocsNewAccess" hideLoadingIndicator :key="refreshPropGrid">
                        <template v-slot:noProperties><span></span></template>
                        <template #default="{properties, editor, renderer}">
                            <div v-for="(property, index) in properties">
                                <PropertiesItem
                                :caption="property.caption 
                                ?? property.name"
                                :title="property.Title"
                                :fieldName="property.name"
                                v-if="propertyIsRequired(property)"
                                :required="propertyIsRequired(property)">
                                    <OPropertiesEditor 
                                        :row="props.dataObject.current.propertiesRows[property.name]" 
                                        class="form-control border border-danger" @blur="saveDataObject"
                                        v-model="props.dataObject.current.properties[property.name]" :config="property"
                                        :overrideContextId="props.dataObject.current.OrgUnit_ID" />
                                </PropertiesItem>
                            </div>
                        </template>
                    </OPropertiesForm>
                </template>
            </PropertiesGroup>
        </template>
        <PropertiesGroup :caption="$t('Properties')">
            <OPropertiesForm :row="props.dataObject.current" viewName="aviw_arena_DocsNewAccess" hideLoadingIndicator :key="refreshPropGrid">
                <template v-slot:noProperties><span></span></template>
                <template #default="{properties, editor, renderer}">
                    <div v-for="(property, index) in properties">
                        <PropertiesItem
                        v-if="!propertyIsRequired(property)"
                        :caption="property.caption ?? property.name"
                        :title="property.Title"
                        :fieldName="property.name">
                            <OPropertiesEditor 
                                :row="props.dataObject.current.propertiesRows[property.name]" 
                                :class="[property.dataType === 'bool' ? 'form-check-input fs-6 m-0 mt-1 ms-1' : 'form-control']" 
                                @blur="saveDataObject"
                                v-model="props.dataObject.current.properties[property.name]" :config="property"
                                :overrideContextId="props.dataObject.current.OrgUnit_ID" />
                        </PropertiesItem>
                        {{ AttachPropListener(props.dataObject._propertiesData._propertiesDataObject) }}
                    </div>
                </template>
            </OPropertiesForm>
        </PropertiesGroup>
        <ODataLookup  
            :data-object="local_dsPropertiesLkp"
            :height="400"
            :bind="sel => handleAddProperty(sel)"
            :noClear="true">
                <template #target="scope" @click="scope.open">
                    <h2 class="mb-0 mt-2 ms-1">
                        <button class="btn btn-sm btn-link py-0 mb-1 ps-0"  :ref="scope.target" style="font-size: 16px;"><i class="bi bi-plus-circle me-1"></i>{{$t("Add Property")}}</button>
                    </h2>
                </template>
                <OColumn name="PropertyName" width="400"></OColumn>
        </ODataLookup>
    </PropertiesGrid>
</template>

<script setup>
import * as DataObjects from 'arena.vue.components.AttributesDBObjects.ts';
import { ref, computed, watch } from 'vue';
import { OPersonsLookup } from 'o365-system-lookups';
import { getOrCreateDataObject } from 'o365-dataobject'
import { OPropertiesGrid as PropertiesGrid } from 'o365-data-components';
import { OPropertiesItem as PropertiesItem } from 'o365-data-components';
import { OPropertiesGroup as PropertiesGroup } from 'o365-data-components';
import { OPropertiesForm, OPropertiesEditor } from 'o365-data-properties';
import { context } from 'o365-modules';
import { OMediaQueryProvider } from 'o365-ui-components';
import { OMatchMedia } from 'o365-ui-components';
import { isMobile } from "o365.GlobalState.ts";

// On change FieldName, new FieldName must be available and exposed in datasource passed from both /document and /newdocument (de-expose old FieldName from these if not used anywhere else in app)

const props = defineProps({
    dataObject: Object,
    collapsed:{
        type:Boolean,
        required:false,
        default:false
    },
    requiredFields:{
        type:Array,
        required:false,
        default:[]
    },
    customFields:{
        type:Array,
        required:false,
        default:[]
    }
})
//const emits = defineEmits(['errorsRefreshed']);
function saveDataObject(){
    props.dataObject.save();
}

const AttachPropListener = (pT) => {
    //console.log(pT)
    if(!propListenerAttached){
        props.dataObject._propertiesData._propertiesDataObject.on('AfterSave', () => {
            props.dataObject.refreshRow()
        })
        propListenerAttached = true;
    }
}

// attributes should be reloaded on change of fields that are part of whereclause on lookup. Or implement smarter whereClause approach (computed)

const requiredFields = ref("");
const vMetaDependencies = {};
const isViewerLoading = ref(true);
const refreshPropGrid = ref(0)
let propListenerAttached = false

const local_dsClassificationCode = getOrCreateDataObject(DataObjects.local_dsClassificationCode),
local_dsReviewClasses = getOrCreateDataObject(DataObjects.local_dsReviewClasses),
local_dsTopics = getOrCreateDataObject(DataObjects.local_dsTopics),
local_dsProjectCodes = getOrCreateDataObject(DataObjects.local_dsProjectCodes),
local_dsDisciplines = getOrCreateDataObject(DataObjects.local_dsDisciplines),
local_dsProjectPhases = getOrCreateDataObject(DataObjects.local_dsProjectPhases),
local_dsMainAreas = getOrCreateDataObject(DataObjects.local_dsMainAreas),
local_dsPersonsLkp = getOrCreateDataObject(DataObjects.local_dsPersonsLkp),
local_dsOrgUnitsLkp = getOrCreateDataObject(DataObjects.local_dsOrgUnitsLkp),
local_dsSubProjects = getOrCreateDataObject(DataObjects.local_dsSubProjects),
local_dsMetaLookup =  getOrCreateDataObject(DataObjects.local_dsMetaLookup),
local_dsMetaFields =  getOrCreateDataObject(DataObjects.local_dsMetaFields),
local_dsCodesLookup =  getOrCreateDataObject(DataObjects.local_dsCodesLookup),
local_dsCodeTypes =  getOrCreateDataObject(DataObjects.local_dsCodeTypes),
local_dsCompanies =  getOrCreateDataObject(DataObjects.local_dsCompanies),
local_dsPlannedChangeLkp =  getOrCreateDataObject(DataObjects.local_dsPlannedChangeLkp),
local_dsDistReqTypes =  getOrCreateDataObject(DataObjects.local_dsDistReqTypes),
local_dsDocumentPackages =  getOrCreateDataObject(DataObjects.local_dsDocumentPackages),
local_dsExtProStatuses =  getOrCreateDataObject(DataObjects.local_dsExtProStatuses),
local_dsExtProcesses =  getOrCreateDataObject(DataObjects.local_dsExtProcesses),
local_dsVoidReasons =  getOrCreateDataObject(DataObjects.local_dsVoidReasons),
local_dsRetentionCodes =  getOrCreateDataObject(DataObjects.local_dsRetentionCodes),
local_dsChecklistsLkp =  getOrCreateDataObject(DataObjects.local_dsChecklistsLkp),
local_dsDocProcesses =  getOrCreateDataObject(DataObjects.local_dsDocProcesses),
local_dsFreeTextLkp =  getOrCreateDataObject({
    id: 'local_dsFreeTextLkp',
    viewName: 'aviw_Arena_FreeTextLookup',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    disableAutoLoad: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Domain_ID",
        masterField: "Domain_ID",
        operator: "equals"
    }],
    fields:
        [{name: "FreeText", type: "string" , sortOrder: 1, sortDirection: "asc"}]
}),
local_dsErrors = getOrCreateDataObject({
    id: 'local_dsErrors',
    viewName: 'atbv_Arena_DocumentsErrors',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Document_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [{name: "Error", type: "string"},
        {name: "FieldName", type: "string" }]
}),
local_dsPersonsWithAccess = getOrCreateDataObject({
    id: 'local_dsPersonsWithAccess',
    viewName: 'aviw_Arena_PersonsWithAccess',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    disableAutoLoad: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Document_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [
            {name: "ID", type: "number"},
            {name: "Name", type: "string"},
            {name: "Document_ID", type: "string"},
            {name: "MobileNo", type: "string"},
            {name: "Email", type: "string"},
            {name: "Represents", type: "string"},
            {name: "LastName", type: "string", sortOrder: 1, sortDirection: "asc"}
        ]
}),
local_dsWBS = getOrCreateDataObject({
    id: 'local_dsWBS',
    viewName: 'aviw_Complete_WBSLookup',
    appId: props.dataObject.appId,
    maxRecords: -1,
    loadRecents: true,
    distinctRows: true,
    disableAutoLoad: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "Parent", type: "string" },
        {name: "NameAndTitle", type: "string" }],
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Domain_ID",
        masterField: "Domain_ID",
        operator: "equals"
    }],
}),
local_dsMetaDependencies = getOrCreateDataObject({
    id: 'local_dsMetaDependencies',
    viewName: 'aviw_Assets_MetaDependencies',
    appId: props.dataObject.appId,
    maxRecords: -1,
    fields:
        [{name: "MetaType", type: "string" },
        {name: "DependsOn", type: "string" }]
}),
local_dsHiddenPlannedFields = getOrCreateDataObject({
    id: 'local_dsHiddenPlannedFields',
    viewName: 'aviw_Arena_HiddenPlannedFields', // could be merged with hiddenfields in document view, but doing it like this now for these fields to remain in CT
    appId: props.dataObject.appId,
    maxRecords: 1,
    fields:
        [{name: "HiddenFields", type: "string" }]
}),
local_dsDocProperties = getOrCreateDataObject({
    id: 'local_dsDocProperties',
    viewName: 'atbv_Arena_DocumentsProperties',
    appId: props.dataObject.appId,
    allowInsert:true,
    allowUpdate:true,
    allowDelete:true,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Document_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [{name: "PropertyName", type: "string"},
        { name: "Value", type: "string" },
        { name: "DateValue", type: "date" },
        { name: "DateTimeValue", type: "datetime" },
        { name: "IntValue", type: "number" },]
}),
local_dsPropertiesLkp = getOrCreateDataObject({
    id: 'local_dsPropertiesLkp',
    viewName: 'aviw_Arena_PropertiesLookup',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string"},
        {name: "PropertyName", type: "string"},
        {name: "PropertyNameTranslated", type: "string"},
        { name: "DataType", type: "string" },
        { name: "Description", type: "date" },
        { name: "Title", type: "datetime" },]
}),
local_dsLCICodesLkp = getOrCreateDataObject({
    id: 'local_dsLCICodesLkp',
    viewName: 'atbv_Arena_LCICodes',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "Closed IS NULL",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        { name: "Description", type: "string" },]
})

local_dsHiddenPlannedFields.load();

const hiddenFields = computed(()=>{
    if (!props.dataObject.state.isLoaded || !local_dsHiddenPlannedFields.state.isLoaded){
        return []
    }

    let allHiddenFields = 
        (props.dataObject.current.HiddenFields ? props.dataObject.current.HiddenFields : "") 
        + (props.dataObject.current.HiddenFields && local_dsHiddenPlannedFields.data[0].HiddenFields ? "," : "") 
        + (local_dsHiddenPlannedFields.data[0].HiddenFields ? local_dsHiddenPlannedFields.data[0].HiddenFields : "");

    // Band-aid fix because incoming data is not matching the names
    allHiddenFields = allHiddenFields.replace('SubProject', 'SubProjects')
    allHiddenFields = allHiddenFields.replace('WBS', 'WBSNameAndTitle')

    // Returns a list of fields that are in allHiddenFields and also have been selected by the user
    let preSelectedHiddenFields = allHiddenFields.split(',')
    preSelectedHiddenFields = preSelectedHiddenFields.filter((field, idx) => field === preSelectedHiddenFields[idx - 1]).filter((vField, vIdx, self) => self.indexOf(vField) === vIdx);

    let filteredHiddenFields = [...preSelectedHiddenFields]
    allHiddenFields?.split(',').map(element => element.trim()).forEach((value, index) => {
        if(value == 'PlannedStart') {}
        else {
            filteredHiddenFields.push(allHiddenFields.split(',').map(element => element.trim())[index]);
        }
    });

    return allHiddenFields ? filteredHiddenFields : []
});

const attributes = ref([]);
const setDefaultAttributes = () => {
    attributes.value = [
        {
            Label: $t('Document Group'),
            Field: 'DocumentGroup',
            InputType: 'input',
            Disabled: true,
            Group:"General"
        },
        {
            Label: $t('Contractor Document No'),
            RequiredField:"ContractorDocNo",
            Field: "ContractorDocumentNo",
            InputType: 'input',
            Group:"Contractor Info"
        },
        {
            Label: $t('Client Document Number'),
            RequiredField:"ClientDocumentNo",
            Field: "ClientDocumentNo",
            InputType: 'input',
            Group:"Client Info"
        },
        {
            Label: $t('Contractor Rev'),
            RequiredField:"ContractorRev",
            Field: "ContractorRev",
            InputType: 'input',
            Group:"Contractor Info"
        },
        {
            Label: $t('Client Rev'),
            RequiredField:"ClientRev",
            Field: "ClientRev",
            InputType: 'input',
            Group:"Client Info"
        },
        {
            Label: $t('Responsible'),
            Field: "Responsible",
            InputType: 'ODataLookup',
            DataObject: local_dsPersonsWithAccess,
            Bind: (sel) => {props.dataObject.current.Responsible_ID = sel.ID, props.dataObject.current.Responsible = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'MobileNo', headerName:'MobileNo', width:100},
                {field:'Email', headerName:'Email', width:200},
                {field:'Represents', headerName:'Represents', width:200}
            ],
            Group:"General"
        },
        {
            Label: $t('Delivered'),
            Field: 'Delivered',
            InputType: 'o-date-picker',
            Disabled: true,
            Group:"General"
        },
        {
            Label: $t('Delivery Verified'),
            Field: 'DeliveryVerifiedDate',
            IfField: 'VerifyDelivery',
            InputType: 'o-date-picker',
            Disabled: true,
            Group:"General"
        },
        {
            Label: $t('Topic'),
            Field: "Topic",
            RequiredField: "Topic",
            InputType: 'ODataLookup',
            DataObject: local_dsTopics,
            Bind: (sel) => {props.dataObject.current.Topic_ID = sel.ID, props.dataObject.current.Topic = sel.NameAndDescription;},
            Columns: [
                {field:'NameAndDescription', headerName:'Name And Description', width:300}
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'",
            Group:"Other"
        },
        {
            Label: $t('Project Code'),
            Field: "ProjectCode",
            RequiredField: "ProjectCode",
            InputType: 'ODataLookup',
            DataObject: local_dsProjectCodes,
            Bind: (sel) => {props.dataObject.current.ProjectCode_ID = sel.ID, props.dataObject.current.ProjectCode = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'OrgUnit', headerName:'OrgUnit', width:200},
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]",
            Group:"Project Information"
        },
                {
            Label: $t('Discipline'),
            Field: "Discipline",
            RequiredField: "Discipline",
            InputType: 'ODataLookup',
            DataObject: local_dsDisciplines,
            Bind: (sel) => {props.dataObject.current.Discipline_ID = sel.ID, props.dataObject.current.Discipline = sel.Name, props.dataObject.current.DisciplineNameAndTitle = sel.NameAndTitle;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]",
            Group:"Project Information"
        },
        {
            Label: $t('Project Phase'),
            Field: "ProjectPhase",
            RequiredField: "ProjectPhase",
            InputType: 'ODataLookup',
            DataObject: local_dsProjectPhases,
            Bind: (sel) => {props.dataObject.current.ProjectPhase_ID = sel.ID, props.dataObject.current.ProjectPhase = sel.Name, props.dataObject.current.ProjectPhaseNameAndTitle = sel.NameAndTitle;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            WhereClause: "DescendantOrgUnit_ID = " + props.dataObject.current.OrgUnit_ID,
            Group:"Project Information"
        },
        {
            Label: $t('Main Area'),
            Field: "MainArea",
            RequiredField: "MainArea",
            InputType: 'ODataLookup',
            DataObject: local_dsMainAreas,
            Bind: (sel) => {props.dataObject.current.MainArea_ID = sel.ID, props.dataObject.current.MainArea = sel.Name, props.dataObject.current.MainAreaNameAndTitle = sel.NameAndTitle;},
            Columns: [
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            WhereClause: "(DocumentGroup_ID = " + props.dataObject.current.DocumentGroup_ID + " OR DocumentGroup_ID IS NULL) AND '" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]",
            Group:"Project Information"
        },
        {
            Label: $t('Sub Projects'),
            Field: "SubProjects",
            RequiredField: "SubProjects",
            InputType: 'ODataLookupCheckBoxes',
            DataObject: local_dsSubProjects,
            Columns: [
                {field:'Name', headerName:'Name', width:400}
            ],
            WhereClause: `${props.dataObject.current.Domain_ID} = Domain_ID`,
            Group:"Project Information"
        },
        {
            Label: $t('Involved Contracts'),
            Field: "InvolvedContracts",
            RequiredField: "InvolvedContracts",
            InputType: 'ODataLookupCheckBoxes',
            DataObject: local_dsOrgUnitsLkp,
            Columns: [
                {field:'NameAndTitle', headerName:$t('Org Unit'), width:300}
            ],
            WhereClause: "[IdPath] LIKE '" + context.idPath + "%'",
            Group:"Other"
        },
        {
            Label: $t('Review Class'),
            Field: "ReviewClass",
            InputType: 'ODataLookup',
            DataObject: local_dsReviewClasses,
            Bind: sel => {props.dataObject.current.ReviewClass_ID = sel.ID, props.dataObject.current.ReviewClass = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
            ],
            Group:"Review"
        },
        {
            Label: $t('Keywords'),
            Field: "Keywords",
            InputType: 'input',
            Group:"Other"
        },
        {
            Label: $t('Progress'),
            Field: "Progress",
            InputType: 'input%',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Progress Weighting'),
            Field: 'ProgressWeighting',
            InputType: 'input',
            Group: 'Delivery Plan'
        },
        {
            Label: $t('WBS'),
            Field: "WBSNameAndTitle",
            InputType: 'ODataLookup',
            DataObject: local_dsWBS,
            Bind: sel => {props.dataObject.current.WBS_ID = sel.ID, props.dataObject.current.WBSNameAndTitle = sel.NameAndTitle;},
            Columns: [
                {field:'Parent', headerName:'Parent', width:200},
                {field:'Title', headerName:'Title', width:200},
                {field:'Name', headerName:'Name', width:200}
            ],
            Group:"Other"
        },
        
        {
            Label: $t('Next Delivery Date'),
            Field: "NextDeliveryDate",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned IDC'),
            Field: "PlannedIDC",
            RequiredField: "PlannedIDC",
            InputType: 'o-date-picker',
            Title: $t('Planned Interdisciplinary Check'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned IFR'),
            Field: "PlannedIFR",
            RequiredField: "PlannedIFR",
            InputType: 'o-date-picker',
            Title: $t('Planned Issued for Review'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned IFD'),
            Field: "PlannedIFD",
            RequiredField: "PlannedIFD",
            InputType: 'o-date-picker',
            Title: $t('Planned Issued for Design'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned IFI/IFU/IFC'),
            Field: "PlannedIFU",
            RequiredField: "PlannedIFU",
            InputType: 'o-date-picker',
            Title: $t('Planned Issued for Use / Construction'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned ASB'),
            Field: "PlannedASB",
            RequiredField: "PlannedASB",
            InputType: 'o-date-picker',
            Title: $t('Planned As Built'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned Start'),
            Field: "PlannedStart",
            RequiredField: "PlannedStart",
            InputType: 'o-date-picker',
            Title: $t('Planned Start'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned Verified'),
            Field: "PlannedVerified",
            RequiredField: "PlannedVerified",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned Released'),
            Field: "PlannedReleased",
            RequiredField: "PlannedReleased",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned Approved'),
            Field: "PlannedApproved",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned TPR'),
            Field: "PlannedTPR",
            RequiredField: "PlannedTPR",
            InputType: 'o-date-picker',
            Title: $t('Planned Third Party Review'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast IDC'),
            Field: "ForecastIDC",
            RequiredField: "ForecastIDC",
            InputType: 'o-date-picker',
            Title: $t('Forecast Interdisciplinary Check'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast IFR'),
            Field: "ForecastIFR",
            RequiredField: "ForecastIFR",
            InputType: 'o-date-picker',
            Title: $t('Forecast Issued for Review'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast IFD'),
            Field: "ForecastIFD",
            RequiredField: "ForecastIFD",
            InputType: 'o-date-picker',
            Title: $t('Forecast Issued for Design'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast IFI/IFU/IFC'),
            Field: "ForecastIFU",
            RequiredField: "ForecastIFU",
            InputType: 'o-date-picker',
            Title: $t('Forecast Issued for Use / Construction'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast ASB'),
            Field: "ForecastASB",
            RequiredField: "ForecastASB",
            InputType: 'o-date-picker',
            Title: $t('Forecast As Built'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast Start'),
            Field: "ForecastStart",
            RequiredField: "ForecastStart",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast Verified'),
            Field: "ForecastVerified",
            RequiredField: "ForecastVerified",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast Released'),
            Field: "ForecastReleased",
            RequiredField: "ForecastReleased",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast Approved'),
            Field: "ForecastApproved",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast TPR'),
            Field: "ForecastTPR",
            RequiredField: "ForecastTPR",
            InputType: 'o-date-picker',
            Title: $t('Forecast Third Party Review'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Expiry Tracked By'),
            Field: "ExpiryTrackedBy",
            InputType: 'ODataLookup',
            DataObject: local_dsPersonsWithAccess,
            Bind: (sel) => {props.dataObject.current.ExpiryTrackedBy_ID = sel.ID, props.dataObject.current.ExpiryTrackedBy = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'MobileNo', headerName:'MobileNo', width:100},
                {field:'Email', headerName:'Email', width:200},
                {field:'Represents', headerName:'Represents', width:200}
            ],
            Group:"Other"
        },
        {
            Label: $t('Expiry Date'),
            Field: "ExpiryDate",
            InputType: 'o-date-picker',
            Group:"Other"
        },
        {
            Label: $t('Person (Applies To)'),
            Field: "Person",
            RequiredField: "Person",
            InputType: 'OPersonsLookup',
            Bind: (sel) => {props.dataObject.current.Person_ID = sel.ID, props.dataObject.current.Person = sel.Name;},
            Group:"Other"
        },
        {
            Label: $t('Retention Code'),
            Field: "RetentionCode",
            InputType: 'ODataLookup',
            DataObject: local_dsRetentionCodes,
            Bind: sel => {props.dataObject.current.RetentionCode = sel.Code;},
            Columns: [
                {field:'Code', headerName:'Code', width:200},
                {field:'Description', headerName:'Description', width:200}
            ],
            Group:"Other"
        },
        {
            Label: $t('Archived'),
            Field: "ArchivedDate",
            InputType: "o-date-picker",
            Group:"Other"
        },
        {
            Label: $t('Archived By'),
            Field: 'ArchivedBy',
            InputType: 'input',
            Disabled: true,
            Group:"Other"
        },
        {
            Label: $t('External Process'),
            Field: "ExternalProcessName",
            InputType: 'ODataLookup',
            DataObject: local_dsExtProcesses,
            Bind: sel => {props.dataObject.current.ExternalProcessName = sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ],
            Group:"External Process"
        },
        {
            Label: $t('External Process Status'),
            Field: "ExternalProcessStatus",
            InputType: 'ODataLookup',
            DataObject: local_dsExtProStatuses,
            Bind: sel => {props.dataObject.current.ExternalProcessStatus = sel.Name; props.dataObject.current.ExternalProcessStatus_ID = sel.ID},
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ],
            Group:"External Process",
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]",
        },
        {
            Label: $t('External Process Due Date'),
            Field: 'ExternalProcessDueDate',
            InputType: "o-date-picker",
            Group:"External Process"
        },
        {
            Label: $t('External Process Sent Date'),
            Field: 'ExternalProcessSentDate',
            InputType: "o-date-picker",
            Group:"External Process"
        },
        {
            Label: $t('External Process Received Date'),
            Field: 'ExternalProcessReceivedDate',
            InputType: "o-date-picker",
            Group:"External Process"
        },
        {
            Label: $t('Document Package'),
            Field: "PackageNameAndTitle",
            RequiredField: "DocumentPackage",
            InputType: 'ODataLookup',
            DataObject: local_dsDocumentPackages,
            Bind: sel => {props.dataObject.current.PackageNameAndTitle = sel.NameAndTitle; props.dataObject.current.DocumentPackage = sel.Name; props.dataObject.current.Package_ID = sel.ID;},
            WhereClause:`'${props.dataObject.current.OrgUnitIdPath}' LIKE IdPath`,
            Columns: [
                {field:'Name', width:150},{field:'Title', width:300}
            ],
            Group:"Project Information"
        },
        {
            Label: $t('Distribution Requirements'),
            Field: "DistributionRequirements",
            InputType: 'ODataLookupCheckBoxes',
            DataObject: local_dsDistReqTypes,
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ],
            WhereClause: `IdPath IS NULL OR '${props.dataObject.current.OrgUnitIdPath}' LIKE IdPath`,
            Group:"Other"
        },
        {
            Label: $t('Checklist'),
            Field: "Checklist",
            InputType: 'ODataLookup',
            DataObject: local_dsChecklistsLkp,
            Columns: [
                {field:'Name', width:150},{field:'Title', width:300}
            ],
            Bind: sel => {props.dataObject.current.Checklist = sel.Name; props.dataObject.current.Checklist_ID = sel.ID;},
            WhereClause: `AccessOrgUnit_ID = '${props.dataObject.current.OrgUnit_ID}'`,
            Group:"Other"
        },
        {
            Label: $t('Company'),
            Field: "Company",
            InputType: 'ODataLookup',
            DataObject: local_dsCompanies,
            Bind: sel => {props.dataObject.current.Company = sel.Name; props.dataObject.current.Company_ID = sel.ID;},
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ],
            Group:"Other"
        },
        {
            Label: $t('LCI Code'),
            Field: "LCICode",
            InputType: 'ODataLookup',
            DataObject: local_dsLCICodesLkp,
            Bind: sel => {props.dataObject.current.LCICode = sel.Name, props.dataObject.current.LCICode_ID = sel.ID;},
            Columns: [
                {field:'Name', headerName:'Name', width:100},
                {field:'Description', headerName:'Description', width:200}
            ],
            Group:"Other"
        },
        {
            Label: $t('Planned Change'),
            Field: "PlannedChange",
            InputType: 'ODataLookup',
            DataObject: local_dsPlannedChangeLkp,
            Bind: sel => {props.dataObject.current.PlannedChange = sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:200},
                {field:'Description', headerName:'Description', width:200}
            ],
            Group:"Delivery Plan"
        },
        {
            Label: $t('Document Process'),
            Field: "DocumentProcess",
            InputType: 'ODataLookup',
            DataObject: local_dsDocProcesses,
            Bind: sel => {props.dataObject.current.DocumentProcess_ID = sel.ID; props.dataObject.current.DocumentProcess = sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ],
            Group:"Other",
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]",
        },
        {
            Label: $t('URL'),
            Field: "URL",
            InputType: "input",
            Group:"Other"
        },
        {
            Label: $t('Alternative Title'),
            Field: "AlternativeTitle",
            InputType: "input",
            Group:"Other"
        },
        {
            Label: $t('Date 1'),
            Field: "Date1",
            RequiredField: "Date1",
            InputType: 'o-date-picker',
            Group:"Other"
        },
        {
            Label: $t('Date 2'),
            Field: "Date2",
            RequiredField: "Date2",
            InputType: 'o-date-picker',
            Group:"Other"
        },
        {
            Label: $t('Date 3'),
            Field: "Date3",
            RequiredField: "Date3",
            InputType: 'o-date-picker',
            Group:"Other"
        },
        {
            Label: $t('Free Text 1'), 
            Field: "FreeText1",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            TextInput:true,
            Bind: sel => {props.dataObject.current.FreeText1 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=1',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Free Text 2'), 
            Field: "FreeText2",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            TextInput:true,
            Bind: sel => {props.dataObject.current.FreeText2 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=2',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Free Text 3'),
            Field: "FreeText3",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            Bind: sel => {props.dataObject.current.FreeText3 = sel.FreeText;},
            TextInput:true,
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=3',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Free Text 4'),
            Field: "FreeText4",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            Bind: sel => {props.dataObject.current.FreeText4 = sel.FreeText;},
            TextInput:true,
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=4',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Free Text 5'),
            Field: "FreeText5",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            TextInput:true,
            Bind: sel => {props.dataObject.current.FreeText5 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=5',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Free Text 6'),
            Field: "FreeText6",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            TextInput:true,
            Bind: sel => {props.dataObject.current.FreeText6 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=6',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Free Text 7'),
            Field: "FreeText7",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            TextInput:true,
            Bind: sel => {props.dataObject.current.FreeText7 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=7',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Free Text 8'),
            Field: "FreeText8",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            TextInput:true,
            Bind: sel => {props.dataObject.current.FreeText8 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=8',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Free Text 9'),
            Field: "FreeText9",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            TextInput:true,
            Bind: sel => {props.dataObject.current.FreeText9 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=9',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Free Text 10'),
            Field: "FreeText10",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            TextInput:true,
            Bind: sel => {props.dataObject.current.FreeText10 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=10',
            Group:"Other",
            TextInput: true
        },
        {
            Label: $t('Confidential'),
            Field: "Confidential",
            Group:"General"
        },
        {
            Label: $t('Disable Native Download'),
            Field: "RestrictNativeDownload",
            Group:"General"
        },
        {
            Label: $t('Documentation for Operations'),
            Field: "DFO",
            InputType: 'select',
            InputOptions: [{name: $t('Yes'), value: true}, {name: $t('No'), value: false}, {name: $t('Not set'), value: null}],
            //NewDocumentOnly: true,
            Group:"General"
        },
        {
            Label: $t('Native File Required'),
            Field: "NativeFileRequired",
            Group:"General"
        },
        {
            Label: $t('As Built Required'),
            Field: "AsBuiltRequired",
            Group:"General"
        },
        {
            Label: $t('3rd Party Review Required'),
            Field: "ThirdPartyReviewRequired",
            Group:"General"
        },

        {
            Label: $t('Is Document Template'),
            Field: "IsTemplate",
            Group:"General"
        },
        {
            Label: $t('Is Correspondence Template'),
            Field: "IsCorrespondenceTemplate",
            Group:"General"
        },
        {
            Label: $t('To Be Archived'),
            Field: "ToBeArchived",
            Group:"General"
        },
        {
            Label: $t('Classification Code'),
            Field: "Classification",
            RequiredField: "Classification",
            InputType: 'ODataLookup',
            DataObject: local_dsClassificationCode,
            Bind: (sel) => {props.dataObject.current.ClassificationCode_ID = sel.ID, props.dataObject.current.Classification = sel.Name, props.dataObject.current.ClassificationNameAndTitle = sel.NameAndTitle},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200}
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'",
            Group:"General"
        },
        {
            Label: $t('Obsolete'),
            Field: 'Obsolete',
            InputType: "o-date-picker",
            Group:"General"
        },
        {
            Label: $t('Obsolete Reason'),
            Field: "ObsoleteReason",
            InputType: 'ODataLookup',
            DataObject: local_dsVoidReasons,
            Bind: sel => {props.dataObject.current.ObsoleteReason_ID = sel.VoidReason_ID; props.dataObject.current.ObsoleteReason= sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:300},
                {field:'Reason', headerName:'Reason', width:300}
            ],
            Group:"General"
        },
        {
            Label: $t('Comments'),
            Field: "Comments",
            InputType: 'OTextArea',
            Group:"General"
        },
    ]

    if (props.customFields.length){
        props.customFields.forEach(obj => {
            if (obj['Label']){
                let newObj = {
                    Label: obj['Label'],
                    Value: obj['Value'],
                    InputType: 'Custom',
                    Group: obj['Group'] ? obj['Group'] : 'Other',
                    Disabled: true
                };

                attributes.value.push(newObj)
            }
        });
    }

    local_dsCodeTypes.load().then(() => {
        local_dsCodeTypes.data.forEach((row,index) => {
            if(row.Name){
                attributes.value.push(
                    {
                        Label: row.Name,
                        Field: "Code"+(row.Sequence),
                        RequiredField: "Code"+(row.Sequence),
                        InputType: 'ODataLookup',
                        DataObject: local_dsCodesLookup,
                        Bind: sel => {props.dataObject.current['Code'+(row.Sequence)+"_ID"] = sel.ID, props.dataObject.current['Code'+(row.Sequence)] = sel.Name;},
                        Columns: [
                            {field:'ID', headerName:'ID', width:100},
                            {field:'Name', headerName:'Name', width:200},
                            {field:'Title', headerName:'Title', width:200}
                        ],
                        WhereClause: "Seq = 'Code"+(row.Sequence)+"' AND '" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]",
                        Group:"Other"
                    }
                )
            }
        })

        local_dsMetaFields.load().then(() => {
            for(let i = 1; i <= 15; i++){
                if(local_dsMetaFields.data[0]['Meta'+i]){
                    attributes.value.push(
                    {
                        Label: local_dsMetaFields.data[0]['Meta'+i],
                        Field: 'Meta'+i,
                        RequiredField: 'Meta'+i,
                        InputType: 'ODataLookup',
                        DataObject: local_dsMetaLookup,
                        Bind: sel => {props.dataObject.current['Meta'+i+'_ID'] = sel.ID ,props.dataObject.data[0]['Meta'+i]= sel.Name; },
                        Columns: [
                            {field:'ID', headerName:'ID', width:100},
                            {field:'Name', headerName:'Name', width:200},
                            {field:'Description', headerName:'Description', width:200},
                        ],
                        beforeopen: function() {
                            let vParentClause = "";
                            if (vMetaDependencies.hasOwnProperty("Meta"+i) && props.dataObject.current[vMetaDependencies["Meta"+i]]){
                                let parentVal = props.dataObject.current[vMetaDependencies["Meta"+i]];
                                vParentClause = `AND ParentObject_Value = '${parentVal}'`
                            }
                            local_dsMetaLookup.recordSource.whereClause = `MetaType = 'Meta${i}' AND Domain_ID = ${props.dataObject.current.Domain_ID} ${vParentClause}`
                            local_dsMetaLookup.load()
                        },
                        Group:"Asset"
                    })
                }
            }

            local_dsErrors.load().then(() => {
                sortAttributes();
            });
        })
    })
}

setDefaultAttributes();


local_dsMetaDependencies.load().then(()=>{
    for(let i = 0; i < local_dsMetaDependencies.data.length; i++){
        if(local_dsMetaDependencies.data[i].DependsOn){
            vMetaDependencies[local_dsMetaDependencies.data[i].MetaType] = local_dsMetaDependencies.data[i].DependsOn;
        }
    }
});

const attributeGroups = [
    {Name: "Required", TranslatedName: $t("Required"), SortOrder:1, Collapsed:false},
    {Name: "General", TranslatedName: $t("General"), SortOrder:2, Collapsed:false},
    {Name: "Review", TranslatedName: $t("Review"), SortOrder:3, Collapsed:true},
    {Name: "Contractor Info", TranslatedName: $t("Contractor Info"), SortOrder:4, Collapsed:true},
    {Name: "Client Info", TranslatedName: $t("Client Info"), SortOrder:5, Collapsed:true},
    {Name: "Delivery Plan", TranslatedName: $t("Delivery Plan"), SortOrder:6, Collapsed:true},
    {Name: "Project Information", TranslatedName: $t("Project Information"), SortOrder:7, Collapsed:true},
    {Name: "External Process", TranslatedName: $t("External Process"), SortOrder:8, Collapsed:true},
    {Name: "Asset", TranslatedName: $t("Asset"), SortOrder:9, Collapsed:true},
    {Name: "Other", TranslatedName: $t("Other"), SortOrder:10, Collapsed:true},
]


const groupedAttributes = computed(() => {
    // Group the attributes by the 'group' property
    const groups = attributes.value.reduce((groups, attribute) => {
        const groupName = attribute.Group || 'Ungrouped';
        if (attributes.value.filter((filterAttribute) => !hiddenFields.value.includes(filterAttribute.Field) && filterAttribute.Group === groupName).length > 0 || groupName === 'Required'){
            if (!groups[groupName]) {
                groups[groupName] = {
                    name: groupName,
                    label: attributeGroups.find((group) => group.Name === groupName).TranslatedName,
                    attributes: [],
                    sortIndex:attributeGroups.find((group) => group.Name === groupName).SortOrder,
                    collapsed:attributeGroups.find((group) => group.Name === groupName).Collapsed
                };
            }
            groups[groupName].attributes.push(attribute)
        }
        return groups;
    }, {});

    if (Object.keys(requiredFields.value).length > 0 && !groups["Required"]) {
        if (!groups["Required"]) {
            const requiredGroup = attributeGroups.find((group) => group.Name === "Required") || {};
            groups["Required"] = {
                name: "Required",
                label:"Required",
                attributes: requiredFields.value,
                sortIndex: requiredGroup.SortOrder || Number.MIN_SAFE_INTEGER, // Ensure it appears at the beginning
                collapsed: requiredGroup.Collapsed || false
            };
        } else {
            groups["Required"].attributes = requiredFields.value;
        }
    }

    // Deleted "Required" group if it has no fields to display
    if(!Array.isArray(groups?.Required?.attributes) && Object.keys(requiredFields.value).length === 0){
        delete groups.Required
    }

    return Object.values(groups).sort((a, b) => a.sortIndex - b.sortIndex);
});

const disabledDO = computed(() => {
    return (((props.dataObject.current.Deleted || props.dataObject.current.Obsolete) && !props.dataObject.current.CanAdministrateDocument) || (props.dataObject.current.Released && !props.dataObject.current.CanAdministrateDocument))
});

watch(()=>props.requiredFields, ()=>{
    getRequiredFields();
    setDefaultAttributes();
})

const getRequiredFields = () => {

    if(!props.requiredFields.length){
        requiredFields.value = {}
        local_dsErrors.data.map((row)=>{
            //if(!props.dataObject.current[row.FieldName]){
                requiredFields.value[row.FieldName] = true;
                requiredFields.value[row.FieldName+'Error'] = row.Error;
            //}
        })
    }else{
        requiredFields.value = {}
        props.requiredFields.forEach((val,i) => {
            requiredFields.value[val] = true
            //requiredFields.value[`${val}Error`] = 'Field Missing';
        })
    }
}

const refreshCustomFields = ()=>{
    attributes.value.forEach((attr)=>{
        if (attr['InputType'] == 'Custom'){
            attr['Value'] = props.customFields.find(f => f.Label == attr.Label)?.Value
        }
    })
}

props.dataObject.on('AfterSave',(pFieldsUpdated) => {
    let updateAttributes = pFieldsUpdated.values.hasOwnProperty('DocumentType_ID') || pFieldsUpdated.values.hasOwnProperty('OrgUnit_ID');

    local_dsErrors.load().then(()=>{
        if(updateAttributes){
            getRequiredFields();
            setDefaultAttributes();
            //sortAttributes();
        } else if (props.customFields.length){
            refreshCustomFields();
        }
    });
})

local_dsErrors.on('DataLoaded', () => {

    getRequiredFields();
    refreshPropGrid.value++;
})

const sortAttributes = () =>{
    attributes.value.forEach((a,b) => {
        if(requiredFields.value[a.RequiredField ?? a.Field]){
            a.Required = true;
            a.Group = "Required";
            a.Title = requiredFields.value[(a.RequiredField ?? a.Field) + "Error"];
        }
    })
    isViewerLoading.value = false
} 

const propertyIsRequired = (pProp) => {
    if(requiredFields.value[`Property_${pProp.name ?? pProp.caption}`])
        return true;
    else
        return false;
}

const handleAddProperty = (pSel) => {
    local_dsDocProperties.createNew({PropertyName:pSel.PropertyName});
    local_dsDocProperties.save().then(() => props.dataObject.load());
}

// checkboxes lookup helpers (parse/save in json)
const getCheckboxesJsonValue = (pJson) => {
    if (!pJson){
        return ''
    }
    try {
        return JSON.parse(pJson).join(', ');
    } catch (e) {
        return pJson;
    }  
}
function checkBoxesIsSelected(plkpItem, pJson, pField){
    if (!pJson){
        return
    }
    try {
        plkpItem.isSelected = JSON.parse(pJson).includes(plkpItem[pField]);
    } catch (e) {
        plkpItem.isSelected = pJson.split(', ').includes(plkpItem[pField]);
    } 
}
function setCheckBoxValues(pSel, pColumn, pField){
    if(pSel && pSel.length){
        const vSel = [...new Set(pSel)]; //Get distinct values
        props.dataObject.current[pColumn] = JSON.stringify(vSel.map(item => item[pField]));
    }else{
        props.dataObject.current[pColumn] = null;
    }
    saveDataObject();
}

</script>

<style scoped>
.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
}

.overlay__inner{
    display:none;
}

select { /*doesn't look great together with the other entries because of boolean-left-padding set on parent in component*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select:focus {
  outline: none;
}
</style>